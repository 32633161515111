<template>
  <div class="page">
    <video
        ref="video_player"
        class="video"
        loop
        autoplay
        playsinline
        muted
        preload="auto"
    >
      <source :src="require(`../../public/video/${videoName}`)">
    </video>
    <div class="page__info-block">
      <div class="page__head-block">
        <div class="logo-dark">
          <img src="~assets/img/logo-main.svg" alt="logo">
        </div>

      </div>
      <p class="page__big-text">
        <span class="page__color-second">
          ТОП-10
        </span>
        СРЕДИ
      </p>
      <p class="page__title">
        крупнейших
        дистрибьюторов
        в россии
      </p>
      <a href="https://download.praxisgroup.ru/About-GDP-Update.mp4"
         target="_blank"
         class="home-btn">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.1 4"/>
          <path d="M31 24L20.5 30.0622L20.5 17.9378L31 24Z" fill="white"/>
        </svg>
        Кратко о бренде
      </a>
    </div>
  </div>
</template>
<script>

import {setTimeout} from "timers";

export default {
  computed: {
    videoName() {
      if (window.innerWidth <= 800) {
        return 'spiral-800.mp4'
      } else if (window.innerWidth <= 1280) {
        return 'spiral-1280.mp4'
      }
      return 'spiral-1600.mp4'
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.$refs.video_player.play();
    }, 300);
  },
  methods: {
    handle() {
      setTimeout(() => {
        this.$refs.video_player.play();
      }, 300);
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/var-style";

.video {
  z-index: 2;
  position: fixed;
  right: -10.3vw;
  top: 0;
  width: 100%;
}

.page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: #000032;

  &__background {
    position: absolute;
    min-height: calc(100vh + 10px);
    min-width: calc(100vw - 400px);
    width: 100%;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    z-index: 2;
    padding-left: 7.8px;
    position: absolute;
    width: 673px;
    top: 0;
    left: 136px;
    height: 100%;
    padding-top: 79.4px;
  }

  &__head-block {
    margin-bottom: 1px;
  }

  &__big-text {
    color: #fff;
    font-weight: 500;
    font-size: 31px;
    line-height: 38px;
    margin-bottom: 10px;
    letter-spacing: -0.3px;

    .page__color-second {
      color: #E6175C;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 64px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 34px;
  }
}

@media screen and (min-width: 1456px) {
  picture {
    position: fixed;
    bottom: -4px;
    left: 255px;
    z-index: 1;

    img {
      position: absolute;
      bottom: -20vh;
      left: 0;
      width: 120vw;
      min-height: 120vh;
    }
  }
}

@media screen and (max-width: 1456px) {
  picture {
    position: fixed;
    bottom: -4px;
    left: 0;
    z-index: 1;

    img {
      width: 100vw;
      min-height: calc(100vh - 80px);
    }
  }
}

.home-btn {
  padding: 8px;
  background: #E6175C;
  display: flex;
  align-items: center;
  width: 227px;
  color: #fff;
  border: none;
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;

  svg {
    margin-right: 8px;
  }

  &:hover {
    box-shadow: 0 20px 36px -8px rgba(102, 10, 41, 0.3);
    background: #DF1659;
  }

  &:active {
    background: #D91657;
  }
}

.logo-dark {
  height: 85px;
  display: flex;
  align-items: center;
  margin-bottom: 115px;

  img {
    height: 100%;
  }

  &__text {
    font-size: 50px;
    line-height: 50px;
    padding-left: 17px;
    text-transform: uppercase;
    position: relative;
    top: 1px;
  }
}

@media screen and (max-width: 1600px) {

  .video {
    width: 1660px;
    right: -15.3vw;
  }
}

@media screen and (max-width: 1456px) {
  .page__background {
    left: -128px;
    min-width: 100vw;
  }
  .video {
    width: 1660px;
    right: -9.3vw;
  }
}

@media screen and (max-width: 1360px) {
  .page {
    width: 100vw;
  }
}

@media screen and (max-width: 1000px) {
  .page__background {
    display: block;
    width: 100%;
    min-width: 100%;
    left: 0;
  }
  .page__info-block {
    padding: 0;
    margin: 0 auto;
    left: auto;
    right: auto;
    top: 10vh;
  }
  .video {
    right: -68.3vw;
  }
}

@media screen and (max-width: 768px) {
  .page__big-text {
    font-size: 16px;
    line-height: 24px;
  }
  .page__info-block {
    height: auto;
  }
  .logo-dark {
    height: 85.5px;
    margin-bottom: 36px;

  }
  .logo-dark__text {
    font-size: 30px;
  }
}

@media screen and (max-width: 700px) {
  .page__title {
    font-size: 32px;
  }
  .page__info-block {
    max-width: 320px;
    top: 30vh;

  }
  picture img {
    width: auto;
    min-height: calc(100vh - 80px);
  }
  .video {
    min-height: 62vh;
    right: -63.3vw;
  }
}

@media screen and (max-width: 600px) {
  .video {
    min-height: 56vh;
    width: 870px;
    right: -41.3vw;
  }
}

@media screen and (max-width: 400px) {
  .video {
    min-height: 56vh;
    width: 880px;
    right: -101.3vw;
  }
}
</style>
